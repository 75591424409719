import React from "react";

const SvgLogout = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path
      d="M255.3 413.9h-142c-8.7 0-15.8-7.1-15.8-15.8V114c0-8.7 7.1-15.8 15.8-15.8h142.1c8.8 0 15.8-7.1 15.8-15.8s-7.1-15.8-15.8-15.8h-142C87.2 66.7 66 88 66 114v284c0 26.1 21.2 47.4 47.4 47.4h142.1c8.8 0 15.8-7.1 15.8-15.8-.1-8.8-7.2-15.7-16-15.7z"
      fill="#b2b2b2"
    />
    <path
      className="logoutIconRight"
      d="M441.3 244.9l-96-94.7c-6.2-6.2-16.2-6-22.3.1-6.2 6.2-6.1 16.2.1 22.3l68.6 67.7H208.1c-8.8 0-15.8 7.1-15.8 15.8 0 8.8 7.1 15.8 15.8 15.8h183.8l-68.6 67.7c-6.2 6.2-6.2 16.1-.1 22.3 3.1 3.1 7.2 4.7 11.2 4.7s8-1.5 11.1-4.5l96-94.7c3-3 4.7-7 4.7-11.2-.2-4.4-1.9-8.4-4.9-11.3z"
      fill="#b2b2b2"
    />
  </svg>
);

export default SvgLogout;
